<!-- for mobile view -->
<div class="mobile" *ngIf="!notshow">
    <div class="em-c-toolbar pagination"
        *ngIf="true">
        <div class="em-c-toolbar__item fixed-content"></div>
        <div class="pagination-mobile-style">
            <div class="em-c-toolbar__item">
                {{firstItemIndex}}-{{lastItemIndex}} of {{totalItems}}
            </div>
            <div class="em-c-toolbar__item">
                <ol class="em-c-pagination"
                    role="navigation"
                    aria-labelledby="pagination-label">
                    <li class="em-c-pagination__item">
                        <a class="em-c-pagination__link"
                        [ngClass]="{ 'em-is-disabled': isFirstPage }"
                        (click)="previousPage()">
                            <app-unity-icon unitySvgName="icon-caret-left" size="small"></app-unity-icon>
                        </a>
                    </li>
                    <li class="em-c-pagination__item">
                        <a class="em-c-pagination__link"
                        [ngClass]="{'em-is-disabled': isLastPage }"
                        (click)="nextPage()">
                            <app-unity-icon unitySvgName="icon-caret-right" size="small"></app-unity-icon>
                        </a>
                    </li>
                </ol>
            </div>
        </div>
    </div>
</div>

<div id="first">
    <ng-container *ngTemplateOutlet="content"></ng-container>
</div>

<div class="em-c-toolbar pagination"
    *ngIf="!notshow">
    <div class="em-c-toolbar__item fixed-content"></div>
    <div class="pagination-mobile-style">
        <div class="em-c-toolbar__item">
            {{firstItemIndex}}-{{lastItemIndex}} of {{totalItems}}
        </div>
        <div class="em-c-toolbar__item">
            <ol class="em-c-pagination"
                role="navigation"
                aria-labelledby="pagination-label">
                <li class="em-c-pagination__item">
                    <a class="em-c-pagination__link"
                       [ngClass]="{ 'em-is-disabled': isFirstPage }"
                       (click)="previousPage()">
                        <app-unity-icon unitySvgName="icon-caret-left" size="small"></app-unity-icon>
                    </a>
                </li>
                <li class="em-c-pagination__item">
                    <a class="em-c-pagination__link"
                       [ngClass]="{'em-is-disabled': isLastPage }"
                       (click)="nextPage()">
                        <app-unity-icon unitySvgName="icon-caret-right" size="small"></app-unity-icon>
                    </a>
                </li>
            </ol>
        </div>
    </div>
</div>
